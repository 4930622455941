import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class TriviaService {
    static async showPopup(msisdn, service, popupName) {
        return await axios.post(`${environmentConfig.triviaService}/show-popup`, {
            msisdn,
            service,
            popupName
        })
    }

    static async getUserStats(msisdn, productId, service) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-stats`, {
            msisdn: msisdn,
            productId: productId,
            service: service
        })
    }

    static async getUserStatsV2(encryptedMsisdn, productId, service) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-stats/v2`, {
            encryptedMsisdn: encryptedMsisdn,
            productId: productId,
            service: service
        })
    }

    static async getTriviaQuestions(msisdn, questionCategoryId, productId, isBonusRound, service) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-questions`, {
            msisdn: msisdn,
            amountOfQuestions: 1,
            questionCategoryId: questionCategoryId,
            productId: productId,
            bonusGame: isBonusRound ? 1 : 0,
            service: service
        })
    }

    static async getTriviaQuestionsV2(encryptedMsisdn, questionCategoryId, productId, isBonusRound, service) {
        return await axios.post(`${environmentConfig.triviaService}/get-user-questions`, {
            encryptedMsisdn: encryptedMsisdn,
            amountOfQuestions: 1,
            questionCategoryId: questionCategoryId,
            productId: productId,
            bonusGame: isBonusRound ? 1 : 0,
            service: service
        })
    }

    static async getLeaderboard(msisdn, service, productId) {
        return await axios.post(`${environmentConfig.triviaService}/get-leaderboard`, {
            msisdn: msisdn,
            numberRows: 10,
            productId,
            service: service
        })
    }

    static async getLeaderboardV2(encryptedMsisdn, service, productId) {
        return await axios.post(`${environmentConfig.triviaService}/get-leaderboard/v2`, {
            encryptedMsisdn: encryptedMsisdn,
            productId,
            numberRows: 10,
            service: service
        })
    }

    static async submitUserAnswer(answer, productId, gameId, isBonusRound, service) {
        return await axios.post(`${environmentConfig.triviaService}/save-user-answers`, {
            answer: answer,
            productId: productId,
            gameId: gameId,
            bonusGame: isBonusRound ? 1 : 0,
            service: service
        })
    }

    static async addFunds(msisdn, productId, gameFundsTypeId, gameFundsAllocationTypeId, amount, fromGameId) {
        return await axios.post(`${environmentConfig.triviaService}/add-game-funds`, {
            msisdn,
            productId,
            gameFundsTypeId,
            gameFundsAllocationTypeId,
            amount,
            fromGameId
        })
    }

    static async addFundsV2(encryptedMsisdn, productId, gameFundsTypeId, gameFundsAllocationTypeId, amount, fromGameId) {
        return await axios.post(`${environmentConfig.triviaService}/add-game-funds/v2`, {
            encryptedMsisdn,
            productId,
            gameFundsTypeId,
            gameFundsAllocationTypeId,
            amount,
            fromGameId
        })
    }

    static async profanityChecker(usernameText) {
        return await axios.get(`https://www.purgomalum.com/service/containsprofanity?text=${usernameText}`, {})
    }

    static async getGameState(msisdn, service) {
        return await axios.post(`${environmentConfig.triviaService}/get-game-state`, {
            msisdn,
            service,
        })
    }

    static async getGameStateV2(encryptedMsisdn, service) {
        return await axios.post(`${environmentConfig.triviaService}/get-game-state/v2`, {
            encryptedMsisdn,
            service,
        })
    }


    static async startGame(msisdn, gameId, bonusGame) {
        return await axios.post(`${environmentConfig.triviaService}/start-game`, {
            msisdn,
            gameId,
            bonusGame,
        })
    }

    static async startGameV2(encryptedMsisdn, gameId, bonusGame) {
        return await axios.post(`${environmentConfig.triviaService}/start-game/v2`, {
            encryptedMsisdn,
            gameId,
            bonusGame,
        })
    }

    static async pauseGame(msisdn, service) {
        return await axios.post(`${environmentConfig.triviaService}/pause-game`, {
            msisdn,
            service,
        })
    }

    static async pauseGameV2(encryptedMsisdn, service) {
        return await axios.post(`${environmentConfig.triviaService}/pause-game/v2`, {
            encryptedMsisdn,
            service,
        })
    }

    static async endGame(gameId, processResults) {
        return await axios.post(`${environmentConfig.triviaService}/end-game`, {
            gameId,
            processResults,
        })
    }

    static async getBadges(msisdn, service) {
        return await axios.post(`${environmentConfig.triviaService}/get-badges`, {
            msisdn,
            service,
        })
    }

    static async getPrizeEntries(msisdn, service) {
        return await axios.post(`${environmentConfig.triviaService}/get-prize-entries`, {
            msisdn,
            service,
        })
    }
}
